/*
 * @Author: WYY 
 * @Description: 章节描述
 * @ModuleName:  章节描述
 * @Date: 2018-09-25 15:41:30 
 * @Last Modified by: WYY
 * @Last Modified time: 2020-03-04 10:55:08
 */
<style lang="less" scoped>
    .Second_title>p{
        color: #0089e9;
        font-size: 25px;
        margin-bottom: 10px;
    }
</style>

<template>
    <div class="T_box">
        <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
        <AudioPlayer :theUrl="AudioPlayerUrl" @audioEndedOver="goon" :config="AudioPlayerConfig" />
        <div class="T_content">
                   
            <div class="Unit_d">
                <div class="Unit_con">
                    <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                    <div class="Second_title">
                        <span class="Second_title_mo">{{Title}}</span>
                        <!-- <div class="Second_title_description">
                            <img src="../../assets/shuoming.png" alt="">
                            <span>Description</span>
                        </div> -->
                    </div>
                    <div class="ucon_content">
                        <div class="t1_dscription" style="text-indent:2em;" v-html="this.Target.description"  v-if="this.Target.description!=''">
                        </div>
                        <div class="t1 t3" v-else>
                            <div>
                                <img src="@/assets/moren.png" alt="">
                            </div>
                        </div>
                        <button class="Goon" @click="goto()" v-if="Pattern!='测试模式'">Go On</button>
                    </div>
                </div>
            </div>
            <div class="T_option">
                <div class="Unit_option">
                    <Time :Pattern='Pattern'/>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import Head from '@/components/head.vue'
    import AudioPlayer from "@/components/AudioPlayer"
    export default {
        data(){
            return {
                Panduan:true,
                Target:{
                    catalog:'',
                    description:'',
                    isEnd:null,
                    title:'',
                },
                RightLeft:false,
                InfoComplate:false,

                UpLayerData:{},

                Vplayer:null,
                Title:'Description',
                AudioPlayerUrl:"",
                AudioPlayerConfig:{
                    autoplay:true,
                    theSpeeds:[],
                },
            }
        },
        components:{Head,AudioPlayer},
        created() {

        },
        async mounted() {
            if(this.menulist.length>0){
                this.Panduan = true;
                this.getinfo();
            }else{
                this.Panduan = false;
            }
            const res =await this.AddStudyLog();
            if(res!='init'){
                const dateRes=await this.AjaxApi.getSevDate();
                sessionStorage.setItem("newChapterStartTime",Date.parse(dateRes.data))
            }
        },
        computed:{
            menulist:function(){
                return this.$store.state.common.menulist;
            },
            datacomplate:function(){
                return this.$store.state.common.datacomplate;
            }, 
            checkid:function(){
                return this.$store.state.common.checkid;
            },
            testPause:function(){
                return this.$store.state.common.testPause;
            },
        },
        watch:{
            datacomplate:function(v){
                if(!this.Panduan){
                    this.getinfo();
                }
            },
            checkid:function(){
                if(this.InfoComplate){
                    this.getinfo();
                }
            },
            testPause:function(v,oldv){
                if(this.Pattern=='测试模式'){
                    if(v&&!oldv){ // 开始
                        if(!!this.QuestionChange){
                            this.QuestionChange();
                        }else{
                            if(!!this.pauseBegin)this.pauseBegin();
                        }
                    }
                    if(oldv&&!v){ // 暂停
                        if(!!this.stayPause)this.stayPause();
                    }
                }
            },
        },
        
        methods:{
            stayPause(){ // 测试模式暂停
                if(!!this.Vplayer) this.Vplayer.pause(); 
            },
            pauseBegin(){
                this.getinfo();
            },
            async getinfo(){
                // console.clear();
                if(!!this.Vplayer) this.Vplayer.pause();
                let data = this.GetTargetItem();     
                console.log('GetTargetItem',data); 
                if(!!data.last){
                    this.Target = data.data[data.last];
                }else{
                    this.Target = data.data;
                }
                let layer = this.Target.ceng.split(',');
                if(layer.length==1){
                    // this.ProductUuid();
                }
                this.UpLayerData = data.updata;
                
                this.Target.title = data.title;
                if(this.Target.isEnd == 1){
                    this.RightLeft = true;
                    this.Title = 'Directions';
                }else{
                    this.Title = 'Description';
                    this.RightLeft = false;
                }
                if(this.Target.allSectionModel == 1){
                    this.$store.commit('setitem',{'name':'Pattern','item':'自由模式'});
                }else if(this.Target.allSectionModel == 2){
                    this.$store.commit('setitem',{'name':'Pattern','item':'闯关模式'});
                }else if(this.Target.allSectionModel == 3){
                    this.$store.commit('setitem',{'name':'Pattern','item':'测试模式'});
                }
                this.$store.commit('setitem',{'name':'threeList','item':[]});
                // let src = this.FlashUrl(this.Target.audioUrl);
                // sessionStorage.setItem('PlayError',0);
                this.targetId = this.Target.id
                // 播放器加载
                this.AudioPlayerUrl = this.Target.audioUrl
                this.InfoComplate = true;
                // 添加学习 记录
                
                
            },
            
            goto(){
                if(this.Pattern == '测试模式'){
                    return;
                }
                this.goon();
            },
            goon(){
                this.$store.commit('setAdd','MenuSwitch');
                // 判断 末子节点
                if(this.Target.isEnd == 1){
                    let role = this.Target.bindRole;
                    let list = this.Target.questionList;
                    if(list.length>0){
                        let typeList = this.$store.state.common.TitleType;
                        let path = '';
                        typeList.forEach(element => {
                            if(element.typeid == list[0].quesTempId){
                                path = element.path;
                            }
                            if(this.Pattern == '测试模式' && (list[0].quesTempId=='04F485861F9D4BCC9FDED30501EC0314'||list[0].quesTempId=='6E019A673B4147F68F7A599C46F304B1')){
                                path = '/home/short';
                            }
                        });
                        if(!!path){
                            sessionStorage.setItem('Pattern',role.ruleTypeName);
                            this.$store.commit('setitem',{'name':'Pattern','item':role.ruleTypeName});
                            this.$router.push(path);
                        }else{
                            this.$message.warning('题目类型有误!');
                        }
                    }else{
                        this.$message.warning('该末子节点尚未绑定题目!');
                    }
                }else{
                    this.Trun();
                }
            },
            Trun(){
                if(!!this.Target.childList&&this.Target.childList.length>0){
                    let list = this.Target.childList;
                    sessionStorage.setItem('checkid',list[0].id);
                    sessionStorage.setItem('targetceng',list[0].ceng);
                    this.$store.commit('setitem',{'name':'dataLayer','item':list[0].ceng})
                    this.$store.commit('setitem',{'name':'checkid','item':list[0].id});
                    // this.$router.push('/home/describe?=' + list[0].id);
                    this.getinfo();
                }
            }
        },
        destroyed(){
            // 播放器销毁
            if(this.Vplayer) this.Vplayer.dispose();
        },
    }
</script>

<style scoped>

.t1_dscription{
    height: calc( 100% - 1.7rem);
    width: 100%;
    box-sizing: border-box;
    margin: 0.5rem;
    font-size: 0.2rem;
    overflow-y: scroll;
}

</style>